import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../helpers/ScrollToTop";
import GlobalStyles from "../GlobalStyles/GlobalStyles";
import ListingItem from "../ListingItem";
import Listings from "../Listings";
import NotFound from "../NotFound";

function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Listings />} />
            <Route path="listing-item/:listingId" element={<ListingItem />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
