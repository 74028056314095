import axios from "axios";
import { useQuery } from "react-query";

const getListings = async () => {
  // get listings from data.json under public folder and if it doesn't exist then get from api
  const listingsEndpoint = process.env.PUBLIC_URL + "/data.json";
  // console.log("listingsEndpoint: ", listingsEndpoint);
  const { data: localData } = await axios.get(listingsEndpoint);
  // console.log("localData: ", localData.results);
  if (localData) {
    // delay
    // await delay(1000);
    return localData.results;
  }

  // get listings from api
  const resourcesEndpoint = process.env.PUBLIC_URL + "/api/resources";
  console.log("resourcesEndpoint: ", resourcesEndpoint);
  const { data: apiData } = await axios.get(resourcesEndpoint);
  return apiData ? apiData.results : [];
};

// function delay(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

const useListings = () => {
  return useQuery("listings", getListings, {
    // stale time 24 hours
    // staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  });
};

export default useListings;
