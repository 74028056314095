import React from "react";
import { ChevronDown } from "react-feather"; // You'll need to install react-feather
import styled from "styled-components/macro";
import { COLORS, QUERIES } from "../../constants";

const SearchAndFilter = ({
  onSearch,
  onCategoryChange,
  categories,
  onTypeChange,
  types,
}) => {
  return (
    <Wrapper>
      <SearchContainer>
        <SearchInput
          type="text"
          placeholder="Search resources..."
          onChange={(e) => onSearch(e.target.value)}
        />
      </SearchContainer>
      <FilterContainer>
        <SelectWrapper>
          <Select
            onChange={(e) => onCategoryChange(e.target.value)}
            defaultValue=""
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Select>
          <SelectIcon>
            <ChevronDown size={20} />
          </SelectIcon>
        </SelectWrapper>
        <SelectWrapper>
          <Select
            onChange={(e) => onTypeChange(e.target.value)}
            defaultValue=""
          >
            <option value="">All Types</option>
            {types.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          <SelectIcon>
            <ChevronDown size={20} />
          </SelectIcon>
        </SelectWrapper>
      </FilterContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 24px 0;

  @media ${QUERIES.tabletAndUp} {
    flex-direction: row;
    padding: 32px 40px;
  }
`;

const SearchContainer = styled.div`
  flex: 1;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid
    ${(props) => (props.theme.isDark ? COLORS.dark[100] : COLORS.gray[100])};
  border-radius: var(--borderRadiusRounded);
  font-size: 1rem;
  background-color: var(--background);
  color: var(--text);

  &::placeholder {
    color: var(--text);
  }

  &:focus {
    outline: none;
    border-color: ${COLORS.violet[200]};
  }
`;

const FilterContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 12px;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 50%;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 20px;
  padding-right: 30px; /* Space for the custom arrow */
  border: 1px solid
    ${(props) => (props.theme.isDark ? COLORS.dark[100] : COLORS.gray[100])};
  border-radius: var(--borderRadius);
  font-size: 1rem;
  background-color: var(--background);
  color: var(--text);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Remove default arrow in IE */
  &::-ms-expand {
    display: none;
  }

  /* Style the options */
  option {
    background-color: var(--background);
    color: var(--text);
    padding: 12px;
  }

  &:focus {
    outline: none;
    border-color: ${COLORS.violet[200]};
  }

  /* Firefox specific styles */
  @-moz-document url-prefix() {
    padding-right: 16px;
    background-color: var(--background);
  }

  /* Edge specific styles */
  @supports (-ms-ime-align: auto) {
    padding-right: 16px;
    background-color: var(--background);
  }
`;

const SelectIcon = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--text);
  // border-left: 1px solid;
  padding-left: 7px;
  /* Ensure the icon doesn't interfere with select clicks */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SearchAndFilter;
