import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { QUERIES, WEIGHTS } from "../../constants";
import AboutModal from "./AboutModal";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <StyledLink aria-label="financefinds - Home" to="/">
            <h2>FinanceFinds</h2>
          </StyledLink>
          <StyledLinkMenu aria-label="financefinds - Home" to="#">
            Submit Resource
          </StyledLinkMenu>
          {/* <ThemeToggle /> */}
        </HeaderContent>
      </HeaderContainer>
      <AboutModal open={open} handleClose={handleClose} />
    </>
  );
};

export default Header;

const StyledLink = styled(Link)`
  outline: none;
  border-radius: 1px;
  transition: all 100ms;
  font-size: 0.8rem;
  font-weight: ${WEIGHTS.normal};
  color: var(--text);
  &:focus {
    outline: 2px solid var(--text);
    outline-offset: 4px;
  }
  text-decoration: none;
  &:active {
    outline: none;
  }

  @media ${QUERIES.tabletAndUp} {
    font-size: 0.9rem;
  }
`;

const StyledLinkMenu = styled(Link)`
  font-size: 0.95rem;
  color: var(--text);
  @media ${QUERIES.tabletAndUp} {
    font-size: 1rem;
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  background: var(--background);
  max-width: 100%;
  margin: 0 auto;
  box-shadow: var(--boxShadow);
  @media ${QUERIES.tabletAndUp} {
    margin-bottom: 1rem;
  }
`;

const HeaderContent = styled.div`
  padding: 14px 25px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${QUERIES.tabletAndUp} {
  }

  @media ${QUERIES.desktopAndUp} {
    width: var(--maxWidth);
    padding: 14px 40px;
  }
`;
