import styled from "styled-components/macro";
import { QUERIES } from "../../constants";

const Container = styled.div`
  position: relative;
  max-width: var(--maxWidth);
  padding: 0 24px;
  margin: 0 auto;
  margin-top: -0.8rem;
  @media ${QUERIES.tabletAndUp} {
    padding: 0 40px;
    margin-top: .5rem;
  }
`;

export default Container;
