import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import styled from "styled-components/macro";

export default function AboutModal({ open, handleClose }) {
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title">
                    <H3>Welcome to FinanceFinds!</H3>
                </DialogTitle>
                <DialogContent>
                    <P>This site is a collection of personal finance resources I’ve found to be useful.</P>
                    <P>These resources were gathered from many different sources like subreddits (r/PersonalFinance, r/FinancialPlanning etc.), blogs (GetRichSlowly, AffordAnything), books, YouTube videos, and even some Instagram accounts.</P>
                    <P>My bookmarks were overflowing, so I decided to create this organized hub to easily search and filter through them.</P>
                    <P>Feel free to browse and share! Hopefully, you'll find something useful too.</P>
                    <P>&mdash;<a href="https://pankaj.co" target='_blank'>Pankaj C.</a></P>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </React.Fragment>
    );
}

const P = styled.p`
  margin-bottom: 8px;
  font-size: 1rem;
`;

const H3 = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
`;
